import React from "react"
import ReactDOM from "react-dom"

import Homepage from "./apps/Homepage/Homepage"

import "./index.scss"


const APPS = {
  Homepage
}


function renderAppInElement(el) {
  /*
   * Renders a react app inside an element.
   */
  let App = APPS[el.dataset.reactApp]

  if (!App) return

  // Assign data attributes to react component
  const props = Object.assign({}, el.dataset)

  ReactDOM.render(<App {...props} />, el)
}


function renderApps() {
  /*
   * Render react app in root element.
   */
  let el = document.getElementById("root")
  renderAppInElement(el)
}


window.addEventListener("load", renderApps)
