import PropTypes from "prop-types"
import React, {Component} from "react"

import Band from "../../components/Band/Band"
import BookingClient from "../../utils/BookingClient"
import BookingForm from "../BookingForm/BookingForm"
import Calendar from "../Calendar/Calendar"
import Paragraph from "../Paragraph/Paragraph"
import scrollToElement from "../../utils/PageScroller"

import "./Booker.scss"


class Booker extends Component {
  /*
  * Allows a user to book time slots
  *
  * Includes the calendar and booking form.
  */
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: null,
      selectedSlotId: null,
      error: null,
      isLoaded: false,
      allSlots: [],
      interactionPending: false
    }
    this.handleBookingBackClick = this.handleBookingBackClick.bind(this)
    this.handleDateClick = this.handleDateClick.bind(this)
    this.handleSlotClick = this.handleSlotClick.bind(this)
    this.handleInteraction = this.handleInteraction.bind(this)
  }

  componentDidMount() {
    /*
    * Fetch time slots from API on load
    */
    const bookingClient = new BookingClient()

    bookingClient.fetchTimeSlots()
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            allSlots: result["time_slots"],
            selectedDate: result["time_slots"][0][0]
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
  }

  componentDidUpdate() {
    /*
    * Deal with pending interaction
    *
    * Scrolling to element needs to be done after render to make sure it gets
    * the correct position of the element that is being scrolled to.
    */
    if (this.state.interactionPending) {
      this.scrollToBookerMain()
      this.setState({interactionPending: false})
    }
  }

  getSlotById(id) {
    for (let i = 0; i < this.state.allSlots.length; i++) {
      let item = this.state.allSlots[i]
      for (let j = 0; j < item[1].length; j++) {
        if (item[1][j]["id"] == id) return item[1][j]
      }
    }
    return {}
  }

  handleDateClick(date) {
    this.setState({selectedDate: date})
  }

  handleSlotClick(id) {
    this.setState({selectedSlotId: id})
    this.scrollToBookerMain()
  }

  handleBookingBackClick() {
    const {toggleExploreBike} = this.props
    this.setState({selectedSlotId: null})
    toggleExploreBike(true)
    this.handleInteraction()
  }

  handleInteraction() {
    /*
    * Handle an interaction
    *
    * Sets state to interaction pending so that interaction can be handled
    * after render.
    */
    this.setState({interactionPending: true})
  }

  scrollToBookerMain() {
    let target = document.getElementById("booker-main")
    scrollToElement(target, 1000)
  }

  render() {
    const {
      selectedSlotId,
      selectedDate,
      error,
      isLoaded,
      allSlots
    } = this.state
    const {toggleExploreBike} = this.props

    if (error) {
      return (
        <div className="booker">
          <div id="booker-main" className="booker__main">
            <div className="booker__content">
              <Paragraph modifiers={["notice"]}>
                Error fetching booking slots: {error.message}.
              </Paragraph>
              <Paragraph modifiers={["notice"]}>
                Please try again or contact house@onepeloton.com for assistance.
              </Paragraph>
            </div>
          </div>
        </div>
      )
    } else if (!isLoaded) {
      return (
        <div className="booker">
          <div id="booker-main" className="booker__main">
            <div className="booker__content">
              <Paragraph modifiers={["notice"]}>Loading...</Paragraph>
            </div>
          </div>
        </div>
      )
    } else if (selectedSlotId) {
      let selectedSlot = this.getSlotById(selectedSlotId)
      return (
        <div className="booker">
          <div id="booker-main" className="booker__main">
            <BookingForm
              selectedSlot={selectedSlot}
              handleBookingBackClick={this.handleBookingBackClick}
              toggleExploreBike={toggleExploreBike}
              handleInteraction={this.handleInteraction}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className="booker">
          <Band modifiers={["drop-shadow"]} contentModifiers={["extra-padded"]}>
            <Paragraph modifiers={["intro"]}>
              Visit the Peloton&nbsp;House in Covent Garden to be the first to experience the Peloton Bike in the UK.
              The Peloton Bike brings you live studio cycling classes taught by world-class
              instructors right from the comfort of home.
            </Paragraph>
            <Paragraph modifiers={["intro"]}>
              Sign up below for a private class on the Bike. We'll give you a personalized bike
              fitting and help you select a class from our library of thousands based on your music
              taste, workout preferences and goals.
            </Paragraph>
          </Band>
          <div id="booker-main" className="booker__main">
            <Calendar
              allSlots={allSlots}
              handleDateClick={this.handleDateClick}
              handleSlotClick={this.handleSlotClick}
              selectedDate={selectedDate}
              handleInteraction={this.handleInteraction}
            />
          </div>
        </div>
      )
    }
  }
}


Booker.propTypes = {
  toggleExploreBike: PropTypes.func
}


export default Booker
