import React, {Component} from "react"
import PropTypes from "prop-types"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import "./Page.scss"


class Page extends Component {
  /*
  * The page including header, content and footer
  */
  render () {
    const {children=null} = this.props

    return (
      <div className="page">
        <div className="page__header">
          <Header/>
        </div>

        <main className="page__content">
          {children}
        </main>

        <div className="page__footer">
          <Footer/>
        </div>
      </div>
    )
  }
}


Page.propTypes = {
  children: PropTypes.node
}


export default Page
