class BookingClient {
  fetchTimeSlots() {
    const API_ROOT = process.env.BOOKING_API_HOST
    return fetch(`${API_ROOT}/time_slots`).then(response => response.json())
  }

  bookAppointment(data) {
    const API_ROOT = process.env.BOOKING_API_HOST
    return fetch(`${API_ROOT}/appointment`, {
      method: "POST",
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        phone_number: data.phoneNumber,
        postal_code: data.postalCode,
        accepts_marketing: data.acceptsMarketing,
        time_slot_id: data.timeSlotId
      }),
      headers:{
        "Content-Type": "application/json"
      }
    }).then(response => response.json())
  }
}


export default BookingClient
