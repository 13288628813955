import PropTypes from "prop-types"
import React, {Component} from "react"

import Heading from "../../components/Heading/Heading"
import Segment from "../../components/Segment/Segment"

import "./FAQ.scss"


class FAQ extends Component {
  /*
  * Renders an FAQ
  */
  constructor(props) {
    super(props)

    this.state = {
      showContents: false,
      expandedHeight: 0
    }

    this.faqContent = {
      current: null
    }

    this.toggleContents = this.toggleContents.bind(this)
    this.updateExpandedHeight = this.updateExpandedHeight.bind(this)
  }

  componentDidMount() {
    /*
    * Listen for window resize to make sure that the max height is correct
    */
    this.updateExpandedHeight()
    window.addEventListener("resize", this.updateExpandedHeight)
  }

  componentWillUnmount() {
    /*
    * Cancel event listeners
    */
    window.removeEventListener("resize", this.updateExpandedHeight)
  }

  updateExpandedHeight() {
    this.setState({expandedHeight: this.faqContent.current.offsetHeight})
  }

  toggleContents() {
    this.setState({showContents: !this.state.showContents})
  }

  render () {
    const {
      heading,
      headingLevel,
      children
    } = this.props
    const {
      showContents,
      expandedHeight
    } = this.state

    return (
      <div className="faq">
        <Segment modifiers={["narrow", "compact"]} contentModifiers={["white", "lower-divider"]}>

          <button
            className={`faq__toggle faq__toggle--${ showContents ? "up": "down" }`}
            onClick={this.toggleContents}
          >
            <Heading level={headingLevel} modifiers={["minor"]}>
              {heading}
            </Heading>
          </button>

          <div
            className={`faq__expander ${ showContents ? "": "faq__expander--collapsed" }`}
            style={{height: (showContents ? expandedHeight + "px" : "0px")}}
          >
            <div
              className="faq__content"
              ref={(faqContent) => this.faqContent.current = faqContent}
            >
              {children}
            </div>
          </div>
        </Segment>
      </div>
    )
  }
}


FAQ.propTypes = {
  heading: PropTypes.string,
  headingLevel: PropTypes.number,
  children: PropTypes.node
}

FAQ.defaultProps = {
  headingLevel: 3
}


export default FAQ
