import PropTypes from "prop-types"
import React, {Component} from "react"

import Band from "../../components/Band/Band"
import Heading from "../../components/Heading/Heading"
import Link from "../Link/Link"
import Paragraph from "../Paragraph/Paragraph"
import ShareBar from "../ShareBar/ShareBar"

import "./BookingConfirmation.scss"
import successImage from "../../img/icons/success.png"
import warningImage from "../../img/icons/warning.png"
import errorImage from "../../img/icons/error.png"


class BookingConfirmation extends Component {
  /*
  * Show confirmation message to user after booking
  *
  * Can either be success, waitlist, or error message.
  */
  constructor(props) {
    super(props)
  }

  renderConfirmError () {
    /*
    * Render the error message
    *
    * This shows if the API returned an error (i.e. there are no more slots).
    */
    return (
      <div className="booking-confirmation">
        <div className="booking-confirmation__content">
          <img
            src={errorImage}
            alt="Error"
            title="Error making reservation"
            className="booking-confirmation__icon"
          />
          <Paragraph modifiers={["notice"]}>
            Your reservation could not be confirmed. Please try again or contact <Link href="mailto:house@onepeloton.com">house@onepeloton.com</Link> for assistance.
          </Paragraph>
        </div>
      </div>
    )
  }

  renderLoadingWidget () {
    return (
      <div className="booking-confirmation">
        <div className="booking-confirmation__content">
          <Paragraph modifiers={["notice"]}>Loading...</Paragraph>
        </div>
      </div>
    )
  }

  renderConfirmation () {
    /*
    * Show confimation message for either success or waitlist
    */
    const {confirmationResponse} = this.props

    if (confirmationResponse.is_waitlisted) {
      return (
        <div className="booking-confirmation">
          <div className="booking-confirmation__content">
            <img
              src={warningImage}
              alt="Alert"
              title="Added to waitlist"
              className="booking-confirmation__icon"
            />
            <Paragraph modifiers={["notice"]}>
              You have been added to the waitlist. Our team will reach out to you
              at {confirmationResponse.email} if we are able to accommodate your visit.
            </Paragraph>
            <Paragraph modifiers={["notice"]}>
              To cancel or change your reservation, please contact <Link href="mailto:house@onepeloton.com">house@onepeloton.com</Link>.
            </Paragraph>
          </div>
        </div>
      )
    } else {
      return (
        <div className="booking-confirmation">
          <div className="booking-confirmation__content">
            <img
              src={successImage}
              alt="Success"
              title="Reservation confirmed"
              className="booking-confirmation__icon"
            />
            <Paragraph modifiers={["notice"]}>
              Your reservation is confirmed and details for your visit have been
              emailed to {confirmationResponse.email}. We're looking forward to meeting you!
            </Paragraph>
            <Paragraph modifiers={["notice"]}>
              To cancel or change your reservation, please contact <Link href="mailto:house@onepeloton.com">house@onepeloton.com</Link>.
            </Paragraph>
          </div>

          <Heading level={3} modifiers={["share", "centred"]}>
            Invite a friend to join you at Peloton House
          </Heading>

          <ShareBar/>
        </div>
      )
    }
  }

  render() {
    const {
      error,
      submitting
    } = this.props

    if (error) {
      return this.renderConfirmError()
    } else if (submitting) {
      return this.renderLoadingWidget()
    } else {
      return this.renderConfirmation()
    }
  }
}


BookingConfirmation.propTypes = {
  error: PropTypes.bool,
  submitting: PropTypes.bool,
  confirmationResponse: PropTypes.object
}


export default BookingConfirmation
