import Moment from "moment"
import PropTypes from "prop-types"
import React, {Component} from "react"

import GLOBALS from "../../globals"

import Arrow from "../../components/Arrow/Arrow"
import Band from "../../components/Band/Band"
import BookingConfirmation from "../../components/BookingConfirmation/BookingConfirmation"
import BookingClient from "../../utils/BookingClient"
import Button from "../../components/Button/Button"
import Field from "../Field/Field"
import Heading from "../Heading/Heading"
import Link from "../Link/Link"

import "./BookingForm.scss"


const loadingDelay = 500


class BookingForm extends Component {
  /*
  * Shows the booking form and corresponding booking confirmation
  *
  * Allows a user to fill in their details (name, email, postcode, marketing)
  * to apply for a particular time slot. Then shows the booking confirmation.
  */
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      postalCode: "",
      acceptsMarketing: false,
      validationErrors: {
        "name": "Required",
        "email": "Required"
      },
      showValidationErrors: false,
      error: false,
      submitted: false,
      showSubmitting: false,
      isBookingConfirmed: false,
      bookingResponse: null
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this)
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this)
    this.handleAcceptsMarketingChange = this.handleAcceptsMarketingChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const {toggleExploreBike} = this.props
    toggleExploreBike(false)
  }

  handleNameChange(event) {
    this.setState({name: event.target.value})
    this.validateName(event.target.value)
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value})
    this.validateEmail(event.target.value)
  }

  handlePhoneNumberChange(event) {
    this.setState({phoneNumber: event.target.value})
    this.validatePhoneNumber(event.target.value)
  }

  handlePostalCodeChange(event) {
    this.setState({postalCode: event.target.value})
    this.validatePostalCode(event.target.value)
  }

  handleAcceptsMarketingChange(event) {
    this.setState({acceptsMarketing: event.target.checked})
    this.validateAcceptsMarketing(event.target.checked)
  }

  validateName(value) {
    /*
    * Ensure name is not empty
    */
    let validationErrors = this.state.validationErrors

    if (value == "") {
      validationErrors["name"] = "Required"
    } else {
      delete validationErrors["name"]
    }
    this.setState({validationErrors: validationErrors})
  }

  validateEmail(value) {
    /*
    * Ensure email is not empty and conforms to email format
    */
    let validationErrors = this.state.validationErrors
    let re = /\S+@\S+\.\S+/

    if (value == "") {
      validationErrors["email"] = "Required"
    } else if (!re.test(String(value).toLowerCase())) {
      validationErrors["email"] = "Invalid Email Address"
    } else {
      delete validationErrors["email"]
    }
    this.setState({validationErrors: validationErrors})
  }

  validatePostalCode() {
  }

  validateAcceptsMarketing() {
  }

  validatePhoneNumber() {
  }

  hasValidationErrors() {
    /*
    * Does the form have any validation errors?
    */
    return Object.keys(this.state.validationErrors).length > 0
  }

  handleSubmit(event) {
    /*
    * Handle submission of the booking form
    *
    * If there are no validation errors, POSTs form data to the API, saving the
    * response to bookingResponse. The API endpoint is the same for both
    * waitlist and normal appointments.
    */
    const {toggleExploreBike, handleInteraction} = this.props

    event.preventDefault()
    handleInteraction()

    if (this.hasValidationErrors()) {
      this.setState({showValidationErrors: true})
      return
    }

    this.setState({submitted: true})
    // Show submitting state after an initial loading delay
    setTimeout(() => {
      if (this.state.submitted) {
        this.setState({showSubmitting: true})
      }
    }, loadingDelay)

    const bookingClient = new BookingClient()

    bookingClient.bookAppointment(
      {
        name: this.state.name,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        postalCode: this.state.postalCode,
        acceptsMarketing: this.state.acceptsMarketing,
        timeSlotId: this.props.selectedSlot.id
      }
    )
      .then(
        (result) => {
          if (result.email && result.created) {
            toggleExploreBike(true)
            this.setState({
              submitted: false,
              showSubmitting: false,
              isBookingConfirmed: true,
              bookingResponse: result
            })
          } else {
            if (result.error == "email already used") {
              this.setState({
                submitted: false,
                showSubmitting: false,
                bookingResponse: result,
                validationErrors: {"email": "Already used"},
                showValidationErrors: true
              })
            } else {
              toggleExploreBike(true)
              this.setState({
                submitted: false,
                showSubmitting:false,
                error: true,
                bookingResponse: result
              })
            }
          }
        },
        () => {
          toggleExploreBike(true)
          this.setState({
            submitted: false,
            showSubmitting: false,
            error: true
          })
        }
      )
  }

  renderForm () {
    /*
    * Show the booking form.
    *
    * This is the same form whether it's for an available slot or the waitlist,
    * just with different wording.
    */
    const { selectedSlot } = this.props
    const reservationAvailable = (
      selectedSlot.regular_appointments_offered - selectedSlot.regular_appointments_reserved > 0
    )
    const marketingInputLabel = (
      <span>
        Do you agree to receive marketing emails from Peloton about the Peloton
        House and other Peloton offerings? Unsubscribe at any time. Your
        personal information will be handled in accordance with our current&nbsp;
        <Link
          href={GLOBALS.privacyLink}
          title="Privacy Policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>.
      </span>
    )

    return (
      <form
        className="booking-form__content"
        onSubmit={this.handleSubmit}
        noValidate
      >
        <Heading level={3} modifiers={["form"]}>
          {
            reservationAvailable ? (
              `Complete your reservation below. We will email you to confirm
              your visit to the Peloton House.`
            ) : (
              `Complete your waitlist reservation below. We will email you if
              we are able to accommodate your visit to Peloton House.`
            )
          }
        </Heading>

        <Field
          name="name"
          value={this.state.name}
          placeholder="Name"
          onChange={this.handleNameChange}
          validationError={this.state.validationErrors.name}
          showValidationErrors={this.state.showValidationErrors}
          required
        />
        <Field
          name="email"
          value={this.state.email}
          type="email"
          placeholder="Email Address"
          onChange={this.handleEmailChange}
          validationError={this.state.validationErrors.email}
          showValidationErrors={this.state.showValidationErrors}
          required
        />
        <Field
          name="phoneNumber"
          value={this.state.phoneNumber}
          type="tel"
          placeholder="Mobile Number"
          onChange={this.handlePhoneNumberChange}
          validationError={this.state.validationErrors.phoneNumber}
          showValidationErrors={this.state.showValidationErrors}
        />
        <Field
          name="postalCode"
          value={this.state.postalCode}
          placeholder="Postal Code"
          onChange={this.handlePostalCodeChange}
          validationError={this.state.validationErrors.postalCode}
          showValidationErrors={this.state.showValidationErrors}
        />
        <Field
          id="marketing-input"
          name="acceptsMarketing"
          value={this.state.acceptsMarketing}
          type="checkbox"
          label={marketingInputLabel}
          onChange={this.handleAcceptsMarketingChange}
          validationError={this.state.validationErrors.acceptsMarketing}
          showValidationErrors={this.state.showValidationErrors}
        />

        <Button modifiers={["major"]} type="submit">
          Complete Reservation
        </Button>
      </form>
    )
  }

  renderBackArrow() {
    /*
    * Show the back arrow to navigate back to a choice of slots
    */
    const {handleBookingBackClick} = this.props
    return (
      <Arrow
        direction="left"
        title="Back to Calendar"
        onClick={() => handleBookingBackClick()}
      />
    )
  }

  render() {
    /*
    * Shows the form or confirmation, depending on where the user is in the flow
    */
    const {selectedSlot} = this.props
    const {
      isBookingConfirmed,
      error,
      bookingResponse,
      showSubmitting
    } = this.state
    let innerNode

    if (error || showSubmitting || isBookingConfirmed) {
      innerNode = (
        <BookingConfirmation
          error={error}
          submitting={showSubmitting}
          confirmationResponse={bookingResponse}
        />
      )
    } else {
      innerNode = this.renderForm()
    }

    return (
      <div className="booking-form">
        <Band modifiers={["drop-shadow"]} contentModifiers={["lr-padded"]}>
          {showSubmitting || isBookingConfirmed ? null : this.renderBackArrow()}
          <Heading level={2} modifiers={["band", "centred"]}>
            {Moment(selectedSlot.start_time).format("dddd, MMMM D [at] H:mm")}
          </Heading>
        </Band>
        {innerNode}
      </div>
    )
  }
}


BookingForm.propTypes = {
  selectedSlot: PropTypes.object,
  handleBookingBackClick: PropTypes.func,
  handleInteraction: PropTypes.func,
  toggleExploreBike: PropTypes.func
}


export default BookingForm
