import PropTypes from "prop-types"
import React, {Component} from "react"

import "./PressQuote.scss"


class PressQuote extends Component {
  /*
  * Displays a quote from a publication along with the publication's logo
  */
  render () {
    const {publication, image, quote} = this.props

    return (
      <div className="press__quote-container">
        <img className="press__image" src={image} alt={publication} />
        <blockquote className="press__quote">"{quote}"</blockquote>
      </div>
    )
  }
}


PressQuote.propTypes = {
  publication: PropTypes.string,
  image: PropTypes.string,
  quote: PropTypes.string
}


export default PressQuote
