import PropTypes from "prop-types"
import React, {Component} from "react"

import "./Field.scss"


class Field extends Component {
  /*
  * A form field, including label, field errors and the field itself
  */
  render () {
    const {
      id,
      type="text",
      modifiers=[],
      inputModifiers=[],
      labelModifiers=[],
      label=null,
      validationError=null,
      showValidationErrors=false,
      ...props
    } = this.props

    let classModifiers = modifiers.map(
      modifier => "field--" + modifier
    ).join(" ")
    let inputClassModifiers = inputModifiers.map(
      modifier => "field__input--" + modifier
    ).join(" ")
    let labelClassModifiers = labelModifiers.map(
      modifier => "field__label--" + modifier
    ).join(" ")

    let startWithLabel = (type !== "checkbox")
    let labelNode = null

    if (label) {
      labelNode = (
        <label
          className={`field__label field__label--${ type } ${ labelClassModifiers }`}
          htmlFor={id}
        >
          {label}
        </label>
      )
    }

    let errorNode = null
    if (showValidationErrors && validationError) {
      errorNode = (
        <span className={`field__error field__error--${ type }`}>
          {validationError}
        </span>
      )
    }

    return (
      <div className={`field field--${ type } ${ classModifiers }`}>
        {startWithLabel ? labelNode : null}
        <input
          id={id}
          type={type}
          className={`field__input field__input--${ type } ${ inputClassModifiers }`}
          {...props}
        />
        {startWithLabel ? null : labelNode}
        {errorNode}
      </div>
    )
  }
}


Field.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  inputModifiers: PropTypes.arrayOf(PropTypes.string),
  labelModifiers: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.node
}


export default Field
