import Moment from "moment"
import PropTypes from "prop-types"
import React, {Component} from "react"

import Button from "../Button/Button"

import "./ReservationRow.scss"


class ReservationRow extends Component {
  /*
  * A row of a ReservationTable including the time, duration and booking button
  */
  render () {
    const {
      slot,
      handleSlotClick
    } = this.props
    let availabilityNode

    let unavailableNode = (
      <Button modifiers={["subtle"]} disabled={true}>
        Unavailable
      </Button>
    )

    if (new Date(slot.appointments_unavailable_after) < Date.now()) {
      // Appointment date has expired
      availabilityNode = unavailableNode
    } else if (slot.regular_appointments_offered - slot.regular_appointments_reserved <= 0) {
      // All appointments booked
      if (slot.waitlist_appointments_offered - slot.waitlist_appointments_reserved > 0) {
        // Waitlist available
        availabilityNode = (
          <Button modifiers={["subtle"]} onClick={() => handleSlotClick(slot.id)}>
            Waitlisted
          </Button>
        )
      } else {
        // Waitlist full
        availabilityNode = unavailableNode
      }
    } else {
      // Appointments available
      availabilityNode = (
        <Button modifiers={["subtle", "continue"]} onClick={() => handleSlotClick(slot.id)}>
          {slot.regular_appointments_offered - slot.regular_appointments_reserved} available
        </Button>
      )
    }

    return (
      <tr className="reservation-row">
        <td className="reservation-row__cell reservation-row__cell--time">
          {Moment(slot.start_time).format("H:mm")}
        </td>
        <td className="reservation-row__cell reservation-row__cell--duration">
          {slot.session_duration.minutes} minutes
        </td>
        <td className="reservation-row__cell reservation-row__cell--availability">
          {availabilityNode}
        </td>
      </tr>
    )
  }
}


ReservationRow.propTypes = {
  slot: PropTypes.object,
  handleSlotClick: PropTypes.func
}


export default ReservationRow
