import PropTypes from "prop-types"
import React, {Component} from "react"

import ReservationRow from "./ReservationRow"

import "./ReservationTable.scss"


class ReservationTable extends Component {
  /*
  * The ReservationTable showing all the time slots available on a given day
  */
  render () {
    const {
      slots,
      handleSlotClick
    } = this.props

    return (
      <table className="reservation-table">
        <tbody>
          {
            slots.map(item => (
              <ReservationRow
                key={item.id}
                slot={item}
                handleSlotClick={handleSlotClick}
              />
            ))
          }
        </tbody>
      </table>
    )
  }
}


ReservationTable.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.object),
  handleSlotClick: PropTypes.func,
}


export default ReservationTable
