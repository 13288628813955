import React, {Component} from "react"

import "./Header.scss"
import logo from "../../img/logos/peloton_logo.png"


class Header extends Component {
  /*
  * Header showing logo and linking back to main Peloton site
  */
  render () {
    return (
      <header className="header">
        <a
          className="header__logo-link"
          href="https://www.onepeloton.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="header__logo" src={logo} alt="Peloton" height="36" />
        </a>
      </header>
    )
  }
}


export default Header
