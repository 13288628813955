import React, {Component} from "react"

import "./ShareBar.scss"


class ShareBar extends Component {
  /*
  * A Share Bar including links to Facebook / Instagram and a print page button
  */
  render () {
    return (
      <div className="share-bar">
        <a
          title="Peloton on Facebook"
          className="share-bar__button share-bar__button--facebook"
          href="https://www.facebook.com/onepeloton"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
        <a
          title="Peloton on Instagram"
          className="share-bar__button share-bar__button--instagram"
          href="https://www.instagram.com/onepeloton"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
        <a
          title="Peloton on Twitter"
          className="share-bar__button share-bar__button--twitter"
          href="http://twitter.com/onepeloton"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
      </div>
    )
  }
}


export default ShareBar
