import PropTypes from "prop-types"
import React, {Component} from "react"

import FAQ from "../../components/FAQ/FAQ"
import Heading from "../../components/Heading/Heading"
import Link from "../../components/Link/Link"
import Paragraph from "../../components/Paragraph/Paragraph"
import Segment from "../../components/Segment/Segment"

import "./FAQList.scss"


class FAQList extends Component {
  /*
  * Renders the list of FAQs
  */
  render () {
    const {headingLevel} = this.props
    const faqHeadingLevel = faqHeadingLevel
    const pelotonLocationLink = (
      <Link
        title="Peloton House on Google Maps"
        href="https://goo.gl/maps/5KLTgprWTt62"
        target="_blank"
        rel="noopener noreferrer"
      >
        11 Floral Street in London
      </Link>
    )
    const emailLink = (
      <Link href="mailto:house@onepeloton.com">
        house@onepeloton.com
      </Link>
    )
    const websiteLink = (
      <Link href="/">
        house.onepeloton.com
      </Link>
    )
    const onePelotonWebsiteLink = (
      <Link href="http://onepeloton.com">
        onepeloton.com
      </Link>
    )

    return (
      <section className="faq-list">
        <Segment modifiers={["narrow", "centred", "compact"]} contentModifiers={["white"]}>
          <Heading level={headingLevel} modifiers={["major", "centred"]}>
            Frequently Asked Questions
          </Heading>
        </Segment>

        <FAQ heading="Where is the Peloton House?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            The Peloton House is located at {pelotonLocationLink}, a five-minute walk from the
            Covent Garden Underground station.
          </Paragraph>
        </FAQ>

        <FAQ heading="What kind of ride can I take?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            We offer thousands of rides, from beginner classes to challenging HIIT rides. With a
            variety of classes for every mood and style, it’s easy to find your fit.
          </Paragraph>
        </FAQ>

        <FAQ heading="Do you have classes for beginners?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            We offer tutorial rides for beginners to introduce proper form and safe,
            endurance-building techniques.
          </Paragraph>
        </FAQ>

        <FAQ heading="When should I arrive for my appointment?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            We encourage you to arrive 15 minutes prior to your booking in order to have plenty
            of time to check in, explore the Peloton House, sign a waiver, and get fitted for
            cycling shoes.
          </Paragraph>
        </FAQ>

        <FAQ heading="How long is my appointment?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            Your test ride booking is a 30 minute slot which allows for your Bike setup and
            explanation, ride selection and a 20-minute ride.
          </Paragraph>
        </FAQ>

        <FAQ heading="Is there a charge for taking a test ride at the Peloton House?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            The test ride experience at the Peloton House is completely complimentary!
            Included with your booking is a tour of the Peloton Bike, a chance to ride in a home
            setting, a hot shower, towels and toiletries.
          </Paragraph>
        </FAQ>

        <FAQ heading="What should I wear?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            Dress comfortably in your favourite workout clothes. We recommend a loose shirt and
            form-fitting shorts or leggings. No need to worry about footwear - we'll provide your
            complimentary cycling shoes.
          </Paragraph>
        </FAQ>

        <FAQ heading="Do I need to bring shoes and water?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            No. We offer complimentary cycling shoes, water and towels. If you prefer to bring your
            own shoes, our pedals are compatible with LOOK Delta cleats.
          </Paragraph>
        </FAQ>

        <FAQ heading="Do you have changing rooms and are there showers?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            We have changing rooms with free lockers and towels. Our showers are stocked with luxury
            beauty products so you can freshen up after class. There are also hairdryers, GHD
            straighteners and deodorant.
          </Paragraph>
        </FAQ>

        <FAQ heading="May I book multiple sessions at the Peloton House?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            We expect many Londoners will be eager to try the Peloton Bike so we are limiting online
            bookings to one per person. Please speak with a staff member if you would like to book
            a second ride.
          </Paragraph>
        </FAQ>

        <FAQ heading="I'm going to miss my appointment, can I reschedule?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            You can cancel your booking by emailing {emailLink}.
            You can book a new appointment at {websiteLink}.
          </Paragraph>
        </FAQ>

        <FAQ heading="How can I cancel my booking?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            If you can't make your appointment, you can cancel your ride by
            emailing {emailLink}.
          </Paragraph>
        </FAQ>

        <FAQ heading="How much is the Peloton Bike and how can I purchase it?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            The Peloton Bike will be for sale from 25 September onwards and costs £1990. You can buy
            the Bike at {onePelotonWebsiteLink} or at the Peloton House.
          </Paragraph>
        </FAQ>

        <FAQ heading="What are the opening hours?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            The Peloton House is open Monday to Friday from 7am - 9pm, Saturday from 9am - 9pm,
            and Sunday from 12pm - 5pm.
          </Paragraph>
        </FAQ>

        <FAQ heading="Is this a studio environment?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            The Peloton House is not a cycling studio. We have set up several areas
            where you can ride the Bike in a private home environment.
          </Paragraph>
        </FAQ>

        <FAQ heading="Can I come in and look around without riding?" headlingLevel={faqHeadingLevel}>
          <Paragraph modifiers={["faq"]}>
            You're welcome to visit us to see the Peloton Bike without riding, but we highly
            encourage signing up for a test ride!
          </Paragraph>
        </FAQ>
      </section>
    )
  }
}


FAQList.propTypes = {
  headingLevel: PropTypes.number
}

FAQList.defaultProps = {
  headingLevel: 2
}


export default FAQList
