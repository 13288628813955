import PropTypes from "prop-types"
import React, {Component} from "react"

import PressQuote from "../../components/Press/PressQuote"

import "./Press.scss"
import forbesImage from "../../img/press/forbes.svg"
import mensHealthImage from "../../img/press/menshealth.svg"
import telegraphImage from "../../img/press/telegraph.svg"
import wsjImage from "../../img/press/wsj.svg"


class Press extends Component {
  /*
  * Displays quotes from the press
  */
  render () {
    return (
      <section className="press">
        <PressQuote publication="The Telegraph" image={telegraphImage} quote="The Peloton package is as good as it sounds" />
        <PressQuote publication="Men's Health" image={mensHealthImage} quote="The best cardio machine on the planet" />
        <PressQuote publication="Forbes" image={forbesImage} quote="Yes, you really can get a better-than-the-gym workout at home" />
      </section>
    )
  }
}

export default Press
