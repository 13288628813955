import PropTypes from "prop-types"
import React, {Component} from "react"

import "./Section.scss"


class Section extends Component {
  /*
  * Renders a section with BEM styles
  */
  render () {
    const {
      id=null,
      modifiers=[],
      children=null
    } = this.props

    let sectionClassModifiers = modifiers.map(
      modifier => "section--" + modifier
    ).join(" ")

    return (
      <section id={id} className={`section ${ sectionClassModifiers }`}>
        {children}
      </section>
    )
  }
}


Section.propTypes = {
  id: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
}


export default Section
