import PropTypes from "prop-types"
import React, {Component} from "react"

import Button from "../Button/Button"

import "./Arrow.scss"


class Arrow extends Component {
  /*
  * A left or right arrow with button for use in carousels
  */
  render () {
    const {
      direction="right",
      modifiers=[],
      ...props
    } = this.props

    const classModifiers = modifiers.map(
      modifier => "arrow--" + modifier
    ).join(" ")

    return (
      <span className={`arrow arrow--${direction} ${classModifiers}`}>
        <Button {...props}/>
      </span>
    )
  }
}


Arrow.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  direction: PropTypes.string
}


export default Arrow
