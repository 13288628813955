import React, {Component} from "react"

import GLOBALS from "../../globals"

import Link from "../Link/Link"
import Paragraph from "../Paragraph/Paragraph"

import "./Footer.scss"
import logo from "../../img/logos/peloton_logo-white.png"


class Footer extends Component {
  /*
  * Footer showing copyright / privacy links
  */
  render () {
    return (
      <footer className="footer">
        <div className="footer__content">
          <a
            className="footer__logo-link"
            href="https://www.onepeloton.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer__logo" src={logo} alt="Peloton" height="50"/>
          </a>
        </div>
        <div className="footer__subfooter">
          <Paragraph modifiers={["smallprint"]}>
            © Peloton 2012 - {(new Date().getFullYear())} Peloton Interactive, Inc.
            All rights reserved.
          </Paragraph>
          <Paragraph modifiers={["smallprint"]}>
            Terms & conditions:<br/>
            Participants must be aged 18 or above. Persons
            under the influence of alcohol or drugs will not be allowed to ride
            the bike. Peloton reserves the right to amend or cancel bookings
            and test rides at any time and for any reason.
          </Paragraph>
          <Paragraph modifiers={["smallprint"]}>
            <Link
              href={GLOBALS.privacyLink}
              title="Privacy Policy"
              target="_blank"
              rel="noopener noreferrer"
              modifiers={["light"]}
            >
              Privacy Policy
            </Link>
          </Paragraph>
        </div>
      </footer>
    )
  }
}


export default Footer
