import Moment from "moment"
import PropTypes from "prop-types"
import React, {Component} from "react"

import Arrow from "../Arrow/Arrow"
import CalendarDateButton from "../CalendarDateButton/CalendarDateButton"

import "./CalendarCarousel.scss"


class CalendarCarousel extends Component {
  /*
  * A Calendar Carousel showing the dates in a specified date list
  *
  * The carousel can be navigated left and right within the date range
  * specified.
  *
  * The number of dates scrolled depends on the screen width: on
  * mobile (<580px), the scroller moves 3 days at a time, on tablet (<800px),
  * 5 at a time and on desktop (>800px), 7 at a time.
  */
  constructor(props) {
    super(props)
    this.state = {
      scrollIndex: this.getInitialScrollIndex(),
      width: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    /*
    * Listen for window resize to make sure that the scroller works correctly
    */
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    /*
    * Cancel event listeners
    */
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth})
  }

  getInitialScrollIndex() {
    /*
    * Determine where the scroller should start
    *
    * This chunks the calendar according to the number of days on the screen,
    * so if 7 days are shown at a time, the scrollIndex should always start on
    * the same day of the week.
    *
    * Chunking examples:
    * 7 days: [M T W T F S S, M T W T F S S, M T W]
    * 5 days: [M T W T F, S S M T W, T F S S M, T W]
    * 3 days: [M T W, T F S, S M T, W T F, S S M, T W]
    */
    let dayIndex = Moment.duration(
      Moment(this.props.selectedDate).diff(
        Moment(this.props.dateList[0].formatted)
      )
    ).asDays()

    let datesOnScreen = this.getDatesOnScreen(window.innerWidth)
    let pageIndex = Math.floor(dayIndex / datesOnScreen)

    return datesOnScreen * pageIndex
  }

  getDatesOnScreen(width=this.state.width) {
    /*
    * Determine how many dates are being shown at once based on screen width
    *
    * Note that the css actually determines how these are displayed using media
    * queries.
    */
    if (width < 580) {
      return 3
    } else if (width < 800) {
      return 5
    } else {
      return 7
    }
  }

  getDateWidth() {
    /*
    * Determine how wide each date is based on screen width
    *
    * Note that the css is what actually specifies these widths - this is just
    * to help the scroller function determine how far to move.
    */
    if (this.state.width < 580) {
      return 80
    } else if (this.state.width < 800) {
      return 100
    } else {
      return 100
    }
  }

  getDuration() {
    /*
    * Determine how long the provided date range is
    */
    const {dateList} = this.props
    let firstDay = Moment(dateList[0].formatted)
    let lastDay = Moment(dateList[dateList.length - 1].formatted)
    return Moment.duration(lastDay.diff(firstDay))
  }

  getMaxScrollIndex() {
    /*
    * Determine the max scroll index based on the chunking of dates on screen
    */
    let duration = this.getDuration()
    let days = duration.asDays()
    let datesOnScreen = this.getDatesOnScreen()
    let quotient = Math.floor(days / datesOnScreen)

    return quotient * datesOnScreen
  }

  goToPrevSlide(e) {
    /*
    * Move the scroll index back to the previous chunk of dates
    */
    e.preventDefault()
    const {handleInteraction} = this.props
    handleInteraction()

    let datesOnScreen = this.getDatesOnScreen()
    let pageIndex = Math.floor((this.state.scrollIndex - 1) / datesOnScreen)

    this.setState({
      scrollIndex: pageIndex * datesOnScreen,
    })
  }

  goToNextSlide(e) {
    /*
    * Move the scroll index forward to the next chunk of dates
    */
    e.preventDefault()
    const {handleInteraction} = this.props
    handleInteraction()

    let datesOnScreen = this.getDatesOnScreen()
    let pageIndex = Math.ceil((this.state.scrollIndex + 1) / datesOnScreen)

    this.setState({
      scrollIndex: pageIndex * datesOnScreen,
    })
  }

  renderNextArrow() {
    /*
    Render next arrow - disable it if we cannot move any further.
    */
    if (this.state.scrollIndex >= this.getMaxScrollIndex()) {
      return (
        <Arrow
          direction="right"
          title="Next"
          disabled={true}
        />
      )
    } else {
      return (
        <Arrow
          direction="right"
          title="Next"
          onClick={e => this.goToNextSlide(e)}
        />
      )
    }
  }

  renderPreviousArrow() {
    /*
    Render previous arrow - disable it if we cannot move back any further.
    */
    if (this.state.scrollIndex == 0) {
      return (
        <Arrow
          direction="left"
          title="Previous"
          disabled={true}
        />
      )
    } else {
      return (
        <Arrow
          direction="left"
          title="Previous"
          onClick={e => this.goToPrevSlide(e)}
        />
      )
    }
  }

  getScrollMonth() {
    /*
    * Show the month of the first day visible in the scroller
    */
    const {dateList} = this.props
    const {scrollIndex} = this.state
    return Moment(dateList[scrollIndex].formatted).format("MMMM Y")
  }

  render() {
    const {handleDateClick, dateList} = this.props
    const {scrollIndex} = this.state

    let marginLeft = scrollIndex * this.getDateWidth() * -1

    return (
      <div className="calendar-carousel">
        {this.renderPreviousArrow()}

        <div className="calendar-carousel__inner">
          <ul className="calendar-carousel__ribbon" style={{marginLeft: marginLeft + "px"}}>
            {
              dateList.map((date) => (
                <li key={date.formatted} className="calendar-carousel__item">
                  <CalendarDateButton
                    date={date}
                    handleDateClick={handleDateClick}
                  />
                </li>
              ))
            }
          </ul>
        </div>

        {this.renderNextArrow()}
      </div>
    )
  }
}


CalendarCarousel.propTypes = {
  handleDateClick: PropTypes.func,
  selectedDate: PropTypes.string,
  dateList: PropTypes.arrayOf(PropTypes.object),
  handleInteraction: PropTypes.func
}


export default CalendarCarousel
