import PropTypes from "prop-types"
import React, {Component} from "react"

import "./Button.scss"


class Button extends Component {
  /*
  A Button element that renders as an anchor tag if an href is supplied
  */
  render () {
    const {
      href=null,
      modifiers=[],
      ...props
    } = this.props

    const Tag = href ? "a" : "button"
    let classModifiers = modifiers.map(
      modifier => "button--" + modifier
    ).join(" ")

    return (
      <Tag
        className={`button ${ classModifiers }`}
        href={href}
        {...props}
      />
    )
  }
}


Button.propTypes = {
  href: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string)
}


export default Button
