import PropTypes from "prop-types"
import React, {Component} from "react"

import "./Link.scss"


class Link extends Component {
  /*
  * A link styled using BEM modifiers
  */
  render () {
    const {
      modifiers=[],
      ...props
    } = this.props

    const classModifiers = modifiers.map(
      modifier => "link--" + modifier
    ).join(" ")

    return (
      <a className={`link ${ classModifiers }`} {...props}/>
    )
  }
}


Link.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string)
}


export default Link
