import Moment from "moment"
import PropTypes from "prop-types"
import React, {Component} from "react"

import "./CalendarDateButton.scss"


class CalendarDateButton extends Component {
  /*
  * Button to select a date from the calendar
  *
  * This shows the weekday initial and day of the month.
  */
  render () {
    const {
      date,
      handleDateClick
    } = this.props

    return (
      <button
        className="calendar-date-button"
        onClick={date.disabled ? null : () => handleDateClick(date.formatted)}
        disabled={date.disabled}
      >
        <span className="calendar-date-button__weekday">
          {Moment(date.formatted).format("ddd")[0]}
        </span>
        <span
          className={
            `calendar-date-button__day ${ date.selected ? "calendar-date-button__day--active": "" }`
          }
        >
          {Moment(date.formatted).format("D")}
        </span>
      </button>
    )
  }
}


CalendarDateButton.propTypes = {
  date: PropTypes.object,
  handleDateClick: PropTypes.func
}


export default CalendarDateButton
