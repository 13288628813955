import React, {Component} from "react"

import Booker from "../../components/Booker/Booker"
import FAQList from "../../components/FAQ/FAQList"
import Heading from "../../components/Heading/Heading"
import Link from "../../components/Link/Link"
import Page from "../../components/Page/Page"
import PageDownControl from "../../components/PageDownControl/PageDownControl"
import Press from "../../components/Press/Press"
import Section from "../../components/Section/Section"
import Segment from "../../components/Segment/Segment"


class Homepage extends Component {
  /*
  * Homepage to render Peloton House Booking System
  */
  constructor(props) {
    super(props)
    this.state = {
      exploreBikeVisible: true
    }

    this.toggleExploreBike = this.toggleExploreBike.bind(this)
  }

  toggleExploreBike(visibility=null) {
    /*
    * Show or hide the explore bike section
    */
    if (visibility == null) {
      visibility = !this.state.exploreBikeVisible
    }
    this.setState({exploreBikeVisible: visibility})
  }

  renderExploreBike() {
    return (
      <Link
        href="https://www.onepeloton.co.uk/bike"
        title="Explore the Bike"
        target="_blank"
        rel="noopener noreferrer"
        modifiers={["light"]}
      >
        <Section modifiers={["explore-bike"]}>
          <Segment modifiers={["centred"]} contentModifiers={["centred", "white"]}>
            <Heading level={0} modifiers={["hero"]}>
              Explore the Bike
            </Heading>
          </Segment>
        </Section>
      </Link>
    )
  }

  render () {
    const {exploreBikeVisible} = this.state

    return (
      <Page>
        <Section modifiers={["home-title"]}>
          <Segment modifiers={["centred"]} contentModifiers={["centred", "white"]}>
            <Heading level={1} modifiers={["hero"]}>
              Ready to Ride, London?
            </Heading>
          </Segment>
          <PageDownControl color="white" pageDownHash="booker"/>
        </Section>

        <Section id="booker" contentModifiers={["full"]}>
          <Booker toggleExploreBike={this.toggleExploreBike} />
        </Section>

        <FAQList headingLevel={2}/>

        <Press />

        {exploreBikeVisible ? this.renderExploreBike() : null}
      </Page>
    )
  }
}

export default Homepage
