import Moment from "moment"
import PropTypes from "prop-types"
import React, {Component} from "react"

import Band from "../Band/Band"
import CalendarCarousel from "../CalendarCarousel/CalendarCarousel"
import Heading from "../Heading/Heading"
import ReservationTable from "../ReservationTable/ReservationTable"

import "./Calendar.scss"


class Calendar extends Component {
  /*
  * Shows a date picker and corresponding list of time slots
  */
  constructor(props) {
    super(props)
    this.state = {
      scrollIndex: 0,
      width: 0
    }
    this.handleDateClick = this.handleDateClick.bind(this)
    this.handleSlotClick = this.handleSlotClick.bind(this)
  }

  handleDateClick(date) {
    this.props.handleDateClick(date)
    this.props.handleInteraction()
  }

  today() {
    return Moment(Date.now()).startOf("day")
  }

  getSlotsByDate(date) {
    /*
    * Gets all the time slots for a particular date
    */
    for (let i = 0; i < this.props.allSlots.length; i++) {
      if (this.props.allSlots[i][0] == date) return this.props.allSlots[i][1]
    }
    return []
  }

  getUpcomingDatesWithSlots() {
    /*
    * Get a list of dates which have slots and are either today or in the future
    */
    let activeDates = []

    for (let i = 0; i < this.props.allSlots.length; i++) {
      if (new Date(this.props.allSlots[i][0]) >= this.today()) {
        activeDates.push(this.props.allSlots[i][0])
      }
    }
    return activeDates
  }

  getFirstMonday() {
    /*
    * Get the first Monday that should show on the date picker
    *
    * This is either the Monday of the first week with slots or the Monday of
    * the current week, whichever is later
    */
    let firstDate = this.props.allSlots[0][0]
    if (Moment(firstDate) < Date.now()) firstDate = Date.now()
    return Moment(firstDate).startOf("isoWeek").format("Y-MM-DD")
  }

  getFinalSunday() {
    /*
    * Get the final Sunday that should show on the date picker
    *
    * This is either the Sunday of the last week with slots or the Sunday of
    * the current week, whichever is later
    */
    let finalDate = this.props.allSlots[this.props.allSlots.length - 1][0]
    if (Moment(finalDate) < Date.now()) finalDate = Date.now()

    return Moment(finalDate).endOf("isoWeek").format("Y-MM-DD")
  }

  getDateList() {
    /*
    * Get a list of dates to show in the date picker
    *
    * Also include whether the date should be disabled (has no slots, or is in
    * the past) and whether the date is currently selected
    */
    let firstMonday = this.getFirstMonday()
    let finalSunday = this.getFinalSunday()
    let activeDates = this.getUpcomingDatesWithSlots()

    let dateList = []
    for (let d = new Date(firstMonday); d <= new Date(finalSunday); d.setDate(d.getDate() + 1)) {
      let formattedDate = Moment(new Date(d)).format("Y-MM-DD")
      dateList.push({
        "formatted": formattedDate,
        "disabled": !activeDates.includes(formattedDate),
        "selected": formattedDate == this.props.selectedDate
      })
    }
    return dateList
  }

  handleSlotClick(id) {
    this.props.handleSlotClick(id)
    this.props.handleInteraction()
  }

  render() {
    /*
    * Show the date picker along with the list of slots for the selected date
    */
    const {selectedDate, handleInteraction} = this.props

    return (
      <div className="calendar">

        <CalendarCarousel
          handleDateClick={this.handleDateClick}
          selectedDate={selectedDate}
          dateList={this.getDateList()}
          handleInteraction={handleInteraction}
        />

        <Band modifiers={["drop-shadow"]}>
          <Heading level={3} modifiers={["band", "centred"]}>
            {Moment(selectedDate).format("dddd, MMMM D")}
          </Heading>
        </Band>

        <ReservationTable
          key={selectedDate}
          date={selectedDate}
          slots={this.getSlotsByDate(selectedDate)}
          handleSlotClick={this.handleSlotClick}
        />
      </div>
    )
  }
}


Calendar.propTypes = {
  handleDateClick: PropTypes.func,
  handleSlotClick: PropTypes.func,
  handleInteraction: PropTypes.func,
  allSlots: PropTypes.arrayOf(PropTypes.array),
  selectedDate: PropTypes.string
}


export default Calendar
