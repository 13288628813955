import PropTypes from "prop-types"
import React, {Component} from "react"

import "./Band.scss"


class Band extends Component {
  /*
  A Band stretching across the width of the screen for banner-style headings
  */
  render () {
    const {
      modifiers=[],
      contentModifiers=[],
      children
    } = this.props

    const bandClassModifiers = modifiers.map(
      modifier => "band--" + modifier
    ).join(" ")
    const contentClassModifiers = contentModifiers.map(
      modifier => "band__content--" + modifier
    ).join(" ")

    return (
      <div className={`band ${ bandClassModifiers }`}>
        <div className={`band__content ${ contentClassModifiers }`}>
          {children}
        </div>
      </div>
    )
  }
}


Band.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  contentModifiers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
}


export default Band
